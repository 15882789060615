import './Home.css';
import { useState, useEffect } from "react";
import Banner from '../assets/media/banner.png';
import Icon1 from '../assets/media/icon-1.png';
import Icon2 from '../assets/media/icon-2.png';
import Icon3 from '../assets/media/icon-3.png';
import MemeBtn from '../assets/media/meme-btn.png';
import Story from '../assets/media/story.gif';
import Tokenomics from '../assets/media/tokenomics.gif';
import Supply from '../assets/media/supply.png';
import Speaker from '../assets/media/speaker.png';
import Burnt from '../assets/media/burnt.png';
import Tax from '../assets/media/tax.png';
import Distribution from '../assets/media/dist.png';
import Level1 from '../assets/media/level-1.png';
import Level2 from '../assets/media/level-2.png';
import Level3 from '../assets/media/level-3.png';
import Level4 from '../assets/media/level-4.png';
import Conference from '../assets/media/conference.png';
import Featured1 from '../assets/media/featured-1.png';
import Featured2 from '../assets/media/featured-2.png';
import Featured3 from '../assets/media/featured-3.png';
import Featured4 from '../assets/media/featured-4.png';
import Featured5 from '../assets/media/featured-5.png';

function Home() {

    const [tokenCopied, setTokenCopied] = useState(false);
    const handleCopyToken = () => {
        const tokenAddressInput = document.getElementById('token-address');
        tokenAddressInput.select();
        document.execCommand('copy');
        setTokenCopied(true);

        // Reset the copied state after 2 seconds
        setTimeout(() => {
            setTokenCopied(false);
        }, 2000);
    };


    return (
        <>

            <section id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-2 order-md-1 mt-4 mt-md-0">
                            <img src={Banner} alt="Banner" className='img img-fluid' />
                        </div>
                        <div className="col-md-6 right order-1 order-md-2">
                            <span className="title">$Mhj</span>
                            <h1>We passionately support Heejin Min!</h1>
                            <a href="#"><img src={MemeBtn} className='img-fuild w-75' alt="Button" /></a>
                            <div className="sns">
                                <a href="#" className="get-btn"><button>GET $Mhj</button></a>
                                <a href="https://twitter.com/MHJcoin" target='_blank' className="icon"><img src={Icon1} alt="Icon" /></a>
                                <a href="https://t.me/MHJcoinChannel" target='_blank' className="icon"><img src={Icon2} alt="Icon" /></a>
                                <a href="#" target='_blank' className="icon"><img src={Icon3} alt="Icon" /></a>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <div className="divider"></div>

            <section id="story">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                            <div className="left">
                                <h2 className="title">STORY</h2>
                                <p><span>An ongoing dispute</span> emerged as HYBE claims ADOR executives aim to separate ADOR from HYBE and seize control. Our goal is to build a community that supports Heejin Min through the project.</p>
                                <p>We aim to facilitate the exchange and sharing of support messages for Heejin Min.</p>
                                <p>We are committed to providing transparent and trustworthy information, actively incorporating members' opinions and feedback, and adhering to principles such as transparency, fairness, and autonomy, as we strive to form a DAO.</p>
                            </div>
                        </div>
                        <div className="col-md-6 text-center right">
                            <img src={Story} alt="Story" className='story-img' />
                        </div>
                    </div>
                </div>

            </section>

            <div className="divider"></div>

            <section id="tokenomics">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-2 order-md-1">
                            <div className="mhj-img text-center"><img src={Tokenomics} alt="Tokenomics" className='img' /></div>

                            <div className="contract-title"><h4>Contract Address</h4></div>
                            <div className="contract-box">
                                <p className="token">
                                    DBLmafTTHUYgyiZGp8yzdVYrB3D3MjiCVLUTZg3uAGZp
                                </p>
                                <input
                                    id="token-address"
                                    type="text"
                                    value="DBLmafTTHUYgyiZGp8yzdVYrB3D3MjiCVLUTZg3uAGZp"
                                    readOnly
                                    style={{ position: "absolute", left: "-9999px" }}
                                />
                                <button className="ms-3 copy-btn" onClick={handleCopyToken}>
                                    <i className="fa fa-copy"></i>
                                    <span
                                        className={tokenCopied ? "tooltip-show" : "tooltip-hide"}
                                    >
                                        Copied!
                                    </span>
                                </button>
                            </div>
                            <p className='mt-3'>*If Min verifies official channel, holders get automatic airdrops</p>

                        </div>
                        <div className="col-md-6 right order-1 order-md-2">
                            <img src={Supply} className='img supply pb-3' alt="Supply" />
                            <h2>TOKENOMICS</h2>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="boxes">
                                        <img src={Speaker} className='p-2' alt="" />
                                        <img src={Burnt} className='p-2' alt="" />
                                        <img src={Tax} className='p-2' alt="" />
                                    </div>
                                </div>
                                <div className="col-sm-8 d-flex align-items-center">
                                    <div className="dist-img"><img src={Distribution} alt="Dist" className="img img-fluid" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="divider"></div>

            <section id="nft">
                <h2 className="text-center section-title">NFTs</h2>
                <div className="text-center section-desc"><p>NFTs will be issued and special staking rewards will be provided.<br />
                    NFT holders can stake NFT and earn rewards.</p></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="nfts d-flex justify-content-center align-items-center flex-wrap">
                                <img src={Level1} alt="Level 1" />
                                <img src={Level2} alt="Level 2" />
                                <img src={Level3} alt="Level 3" />
                                <img src={Level4} alt="Level 4" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="divider-2"></div>

            <section id="roadmap">
                <h2 className="text-center section-title text-white">ROADMAP</h2>
                <div className="container" d-flex>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="map-1">
                                <h4 className="text-white text-md-center text-start title">PHASE <span>1</span></h4>
                                <div className="box-1">
                                    <ul className="arrow-list">
                                        <li>FAIRLAUNCH ON PINKSALE</li>
                                        <li>LAUNCH</li>
                                        <li>DEXTOOLS, DEXSCREENER TRENDING</li>
                                        <li>COMMUNITY BUILDING</li>
                                        <li>CMC, CG LISTING</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="map-2">
                                <h4 className="text-white text-md-center text-start title">PHASE <span>2</span></h4>
                                <div className="box-2">
                                    <ul className="arrow-list">
                                        <li>POST-LAUNCH MARKETING</li>
                                        <li>10,000 HOLDERS</li>
                                        <li>SUPPORT MEME CULTURE</li>
                                        <li>NFT STAKING</li>
                                        <li>TOP 20 CEX LISTING</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="map-3">
                                <h4 className="text-white text-md-center text-start title">PHASE <span>3</span></h4>
                                <div className="box-3">
                                    <ul className='arrow-list'>
                                        <li>TOP 10 CEX LISTING</li>
                                        <li>DAO VOTING</li>
                                        <li>AMBASSADOR ANNOUNCEMENT</li>
                                        <li>MEME TAKEOVER</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <div className="divider-2"></div>

            <section id="conference">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <img src={Conference} alt="Conference" className='img img-fluid' />
                        </div>
                    </div>
                </div>
            </section>

            {/* <div className="divider"></div> */}

            {/* <section id="featured">
                <div className="container bg-white">
                    <div className="row">
                        <h2 className="text-center section-title">FEATURED IN</h2>
                        <div className="col-12">
                            <div className="first-row">
                                <img src={Featured1} className='img' alt="Featured" />
                                <img src={Featured2} className='img' alt="Featured" />
                                <img src={Featured3} className='img' alt="Featured" />
                            </div>
                            <div className="second-row">
                                <img src={Featured4} className='img' alt="Featured" />
                                <img src={Featured5} className='img' alt="Featured" />
                            </div>

                        </div>
                    </div>
                </div>

            </section> */}

        </>

    );
}

export default Home;