import './Header.css';
import Logo from '../assets/media/logo.png';

function Header() {
    return (
        <>
            <nav class="navbar navbar-expand-lg">
                <div class="container align-items-md-start align-items-center">
                    <a class="navbar-brand" href="/"><img src={Logo} alt="Logo" /></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="text-white"><i class="fas fa-bars"></i></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav w-100 justify-content-between">
                            <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="#home">HOME</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#story">STORY</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#tokenomics">TOKENOMICS</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#nft">NFTs</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#roadmap">ROADMAP</a>
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </nav>
        </>

    );
}

export default Header;