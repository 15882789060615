import './Footer.css';
import Logo from '../assets/media/logo.png';
import Icon1 from '../assets/media/ficon-1.png';
import Icon2 from '../assets/media/ficon-2.png';
import Icon3 from '../assets/media/ficon-3.png';

function Footer() {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="img d-flex justify-content-center justify-content-md-start align-items-center pb-md-0 pb-4"><img src={Logo} alt="Logo" className="footer-logo" /><span className="title">$Mhj</span></div>
                        </div>
                        <div className="col-md-6">
                            <div className="sns d-flex justify-content-md-end justify-content-center align-items-center">
                                <a href="https://twitter.com/MHJcoin" target='_blank'><img src={Icon1} alt="Icon" /></a>
                                <a href="https://t.me/MHJcoinChannel" target='_blank'><img src={Icon2} alt="Icon" /></a>
                                <a href="#" target='_blank'><img src={Icon3} alt="Icon" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <section id="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center text-white pt-4">
                            $MHJ has no association with news related to Min Hee-jin’s HYBE.
                            This token is simply a meme coin with no intrinsic value or expectation of financial return.
                            © 2024 by $MHJ All rights reserved.
                        </div>
                    </div>
                </div>
            </section>

        </>

    );
}

export default Footer;